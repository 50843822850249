import React from 'react';
import Button from 'components/Button';

const DownloadAction = ({
  callToAction: {
    style,
    buttonText,
    id,
    action: {
      file: {
        file: { url },
      },
    },
  },
  prevAction,
  buttonStyle,
  linkStyle,
  extraStyle,
  isTextWithOrPrefix,
}) => {
  return (
    <Button
      type={
        style?.includes('Button')
          ? `${buttonStyle} ${extraStyle}`
          : `${linkStyle} ${extraStyle}`
      }
      key={`heroSectionButton_${id}`}
      handleClick={() => window.open(url)}
    >
      {prevAction?.includes('Button') && style === 'Text'
        ? `${isTextWithOrPrefix ? 'Or, ' : ''}${buttonText}`
        : buttonText}
    </Button>
  );
};

export default DownloadAction;
