import React from 'react';
import AvailableRegistrationDynamicAction from 'features/DynamicActions/AvailableRegistrationDynamicAction';
import SECTIONS from 'src/utils/sectionConstants';

const DynamicRedirectAction = props => {
  const action = props.callToAction.action;
  switch (action.condition) {
    case SECTIONS.DynamicRedirectActionConditions.userAvailableAuctions:
      return <AvailableRegistrationDynamicAction {...props} />;
    default:
      return <div />;
  }
};

export default DynamicRedirectAction;
