const sections = {
  CollectionOfLists: 'ContentfulWebCollectionOfParagraphsLists',
  ListOfHeroSections: 'ContentfulWebListOfHeroSections',
  RedirectAction: 'ContentfulWebRedirectAction',
  DownloadAction: 'ContentfulWebDownloadAction',
  OpenFormAction: 'ContentfulWebOpenFormAction',
  DynamicRedirectAction: 'ContentfulWebDynamicRedirectAction',
  ParagraphWithHeadline: 'ContentfulWebParagraphWithHeadline',
  DynamicRedirectActionConditions: {
    userAvailableAuctions: 'User Has Auctions Available For Registration',
  },
  DynamicSections: {
    type: 'ContentfulWebDynamicSections',
    featuredVehicles: 'Featured Vehicles',
    verticalUpcomingAuctions: 'Vertical Upcoming Auctions',
    horizontalUpcomingAuctions: 'Horizontal Upcoming Auctions',
    horizontalUpcomingOnlineAuctions: 'Horizontal Upcoming Online Auctions',
    auctionResults: 'Auction Results',
    privateSales: 'Private Sales',
    activeJobs: 'Active Jobs',
    availableLots: 'Available Lots',
    orderACatalogue: 'Order a Catalogue',
  },
  HeroImage: 'ContentfulWebHeroImage',
  SideBySide: 'ContentfulWebSideBySide',
  ListOfCards: 'ContentfulWebListOfCards',
  RichText: 'ContentfulWebRichTextSection',
  ListOfCta: 'ContentfulWebListOfCta',
  Card: 'ContentfulWebCard',
  webVideoMedia: 'webVideoMedia',
  videoLinkWrapper: 'videoLinkWrapper',
  webCard: 'webCard',
  webSectionWrapper: 'webSectionWrapper',
  ListOfContacts: 'ContentfulWebListOfContacts',
  ListOfVenues: 'ContentfulWebListOfVenues',
  VehicleCatalogOptions: 'ContentfulWebVehicleCatalogueOptions',
  Timeline: 'ContentfulWebTimeline',
  VideoMedia: 'ContentfulWebVideoMedia',
  imagesWrapper: 'imagesWrapper',
  auction: 'auction',
};

export default sections;
