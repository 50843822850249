import React, { useState, useEffect } from 'react';
import { useRegistrationAuctions } from 'services/graphql/hooks/RegistrationAuctions';
import { GET_USER_REGISTERED_CAMPAIGNS } from 'services/graphql/queries/profile';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import Button from 'components/Button';
import RedirectAction from 'components/CTA/RedirectAction';
import Spinner from 'src/components/Spinner';
import { useCheckNotAuthenticatedUsers } from 'shared/helpers/Auth';
import { useAuth0 } from '@auth0/auth0-react';

const AvailableRegistrationDynamicAction = props => {
  const [conditionPass, setConditionPass] = useState(true);
  const isNotAuthenticated = useCheckNotAuthenticatedUsers();
  const { auctions } = useRegistrationAuctions();
  const { loading, data } = useAuthenticatedQuery(
    GET_USER_REGISTERED_CAMPAIGNS,
    {
      skip: !!isNotAuthenticated || auctions.length === 0,
    }
  );
  const { isLoading: authLoading } = useAuth0();

  useEffect(() => {
    const userCampaigns =
      data?.userRegisteredCampaigns?.registeredCampaigns || [];
    const userCampaignIds = userCampaigns.map(campaign => campaign.campaignId);
    const availableAuctions = auctions.filter(
      auction => !userCampaignIds.includes(auction.campaignId)
    );
    if (availableAuctions.length === 0) setConditionPass(false);
  }, [data]);

  const disabledButtonProps = {
    type: props.callToAction.style?.includes('Button')
      ? `${props.buttonStyle} ${props.extraStyle}`
      : `${props.linkStyle} ${props.extraStyle}`,
    key: `heroSectionButton_${props.id}`,
    linkAsButton: false,
    path: props.callToAction.action.url,
    disabled: true,
  };
  return (
    <>
      {loading && !authLoading && <Spinner />}
      {isNotAuthenticated || conditionPass ? (
        <RedirectAction
          {...props}
          userIsnotAuthenticated={isNotAuthenticated}
        />
      ) : auctions.length === 0 ? (
        <Button {...disabledButtonProps}>No Auctions Available</Button>
      ) : (
        <Button {...disabledButtonProps}>
          {props.callToAction.action.conditionFailureText}
        </Button>
      )}
    </>
  );
};

export default AvailableRegistrationDynamicAction;
